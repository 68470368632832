import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  Home,
  Links,
  Login,
  Register,
  Users,
  Verify,
} from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/users" element={<Users />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/verify/:token" element={<Verify />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/:short" element={<Links />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
