import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import Footer from "../components/Footer";

const Home = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [logedIn, setLogedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refreshToken();
  });

  const refreshToken = async () => {
    try {
      await axios.get(`${apiUrl}/token`);
      setLogedIn(true);
      setLoading(false);
    } catch (error) {
      setLogedIn(false);
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar logedIn={logedIn} loading={loading} />
      <div className="container mt-5 py-4 py-md-5">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus
        qui, nostrum temporibus nobis ratione voluptates laborum, corrupti fuga
        culpa hic pariatur! Atque itaque reprehenderit vel perferendis fugiat
        vero tempora consequatur.
      </div>
      <Footer />
    </div>
  );
};

export default Home;
