import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import NavAuth from "../components/NavAuth";

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);

  const refreshToken = async () => {
    try {
      setLoadingLogin(true);
      await axios.get(`${apiUrl}/token`);
      navigate("/dashboard");
    } catch (error) {
      setLoadingLogin(false);
    }
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      setLoadingLogin(true);
      const response = await axios.post(`${apiUrl}/login`, {
        email,
        password,
      });

      toast.success(response.data.msg, {
        position: "bottom-right",
      });

      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      setLoadingLogin(false);
      if (error.response.status === 400) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
      }
    }
  };

  const continueWithGoogle = useGoogleLogin({
    onSuccess: async (responseGoogle) => {
      try {
        setLoadingGoogle(true);
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${responseGoogle.access_token}`,
            },
            withCredentials: false,
          }
        );
        const name = res.data.name;
        const email = res.data.email;
        const response = await axios.post(`${apiUrl}/google`, {
          name,
          email,
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
        });

        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.msg, {
            position: "bottom-right",
          });
        }
        setLoadingGoogle(false);
      }
    },
  });

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="col-12 col-md-8 col-lg-6 col-xl-5 p-3">
        <NavAuth />
        <div className="card card-body">
          <form onSubmit={login}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="email@domain.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="********"
                minLength={8}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="invalid-feedback">
                Minimum 8 characters, consisting of 1 uppercase and lowercase
                letter, 1 special character.
              </div>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary btn-w"
                type="submit"
                disabled={!email || !password || loadingLogin}
              >
                {loadingLogin ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </form>
          <div className="divider d-flex align-items-center my-3">
            <p className="text-center fw-bold mx-3 mb-0 text-muted">OR</p>
          </div>
          <div className="d-grid">
            <button
              className="btn btn-outline-danger btn-w"
              onClick={() => continueWithGoogle()}
              disabled={loadingLogin || loadingGoogle}
            >
              {loadingGoogle ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Continue with Google"
              )}
            </button>
          </div>
        </div>
        <div className="text-center mt-3">
          <Link to={"/"}>Back to Home</Link>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
