import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function LogoutModal() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const logout = async () => {
    try {
      setLoading(true);
      await axiosJWT.delete(`${apiUrl}/logout`);
      handleClose();
      navigate("/");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Link className="dropdown-item" onClick={handleShow}>
        <i className="bi bi-power me-1"></i>Logout
      </Link>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={loading}>
            Close
          </Button>
          <Button
            variant="danger"
            type="submit"
            onClick={() => logout()}
            disabled={loading}
          >
            {loading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Logout"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LogoutModal;
