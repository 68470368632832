import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

function QRModal({ short, msg }) {
  const url = window.location.href.split("/dashboard").join("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className="btn btn-sm btn-secondary me-1"
        onClick={handleShow}
        title="QR Code"
      >
        <i className="bi bi-qr-code-scan"></i>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center p-4">
          <div className="d-none d-lg-block">
            <QRCodeSVG value={`${url}/${short}`} width={300} height={300} />
          </div>
          <div className="d-block d-lg-none">
            <QRCodeSVG value={`${url}/${short}`} width={250} height={250} />
          </div>
          <div className="mt-3">
            <Link
              onClick={() => {
                navigator.clipboard.writeText(`${url}/${short}`);
                msg("Copied!");
              }}
              title="Copy to Clipboard"
            >
              {`${url
                .split("http://")
                .join("")
                .split("https://")
                .join("")}/${short}`}
            </Link>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Link
            className="btn btn-primary"
            to={`${url}/${short}`}
            target="_blank"
          >
            Visit Link
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QRModal;
