import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const Links = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const { short } = useParams();

  useEffect(() => {
    getLink();
    // eslint-disable-next-line
  }, []);

  const getLink = async () => {
    try {
      const response = await axios.get(`${apiUrl}/link/${short}`);
      window.location.assign(response.data.full);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      {loading ? (
        <div className="d-block text-center">
          <h3>
            Link<span className="text-danger">In</span>
          </h3>
          <div
            className="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h1>404 Not Found</h1>
          <p>Please check again or contact the creator</p>
          <Link to={"/"}>Back to Home</Link>
        </div>
      )}
    </div>
  );
};

export default Links;
