import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavAuth = () => {
  const location = useLocation();

  return (
    <div>
      <div className="btn-group mb-3 w-100">
        <Link
          to={"/login"}
          className={
            "btn btn-outline-secondary " +
            (location.pathname !== "/login" ? "" : "active")
          }
        >
          Login
        </Link>
        <Link
          to={"/register"}
          className={
            "btn btn-outline-secondary " +
            (location.pathname !== "/register" ? "" : "active")
          }
        >
          Register
        </Link>
      </div>
    </div>
  );
};

export default NavAuth;
