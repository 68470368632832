import axios from "axios";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";

function SettingsModal({ user, msg }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const [passMatch, setPassMatch] = useState(false);
  const [exist, setExist] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (email !== user.email) {
      const delayDebounceFn = setTimeout(() => {
        check();
      }, 100);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setExist(false);
    }
    // eslint-disable-next-line
  }, [email]);

  const check = async () => {
    try {
      await axiosJWT.post(`${apiUrl}/profile`, {
        email,
      });
      setExist(false);
    } catch (error) {
      if (error.response.status === 400) {
        setExist(true);
      }
    }
  };

  useEffect(() => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    setCheckPassword(regex.test(password));
    // eslint-disable-next-line
  }, [password]);

  useEffect(() => {
    if (!checkPassword) setConfPassword("");
    // eslint-disable-next-line
  }, [checkPassword]);

  useEffect(() => {
    if (confPassword && confPassword === password) {
      setPassMatch(true);
    } else {
      setPassMatch(false);
    }
    // eslint-disable-next-line
  }, [password, confPassword]);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const update = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axiosJWT.patch(`${apiUrl}/profile`, {
        name,
        email,
        password,
      });
      handleClose();
      setLoading(false);
      msg(response.data.msg);
    } catch (error) {
      setLoading(false);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPassword("");
    setConfPassword("");
  };
  const handleShow = () => {
    setName(user.name);
    setEmail(user.email);
    setShow(true);
  };

  return (
    <>
      <Link className="dropdown-item" onClick={handleShow}>
        <i className="bi bi-gear-fill me-1"></i>
        Settings
      </Link>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <form onSubmit={update}>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Your name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Someone"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className={
                  "form-control " + (!email ? "" : exist ? "is-invalid" : "")
                }
                id="email"
                placeholder="email@domain.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="invalid-feedback">The email already exists.</div>
            </div>
            <div>
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className={
                  "form-control " +
                  (!password ? "" : checkPassword ? "is-valid" : "is-invalid")
                }
                id="password"
                placeholder="********"
                minLength={8}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {!password ? (
                <div id="passwordHelp" className="form-text">
                  Leave it blank if you don't want to change it.
                </div>
              ) : (
                ""
              )}
              <div className="invalid-feedback">
                8 characters minimum, consisting of 1 uppercase and lowercase
                letter, 1 special character.
              </div>
            </div>
            {checkPassword ? (
              <div className="mt-3">
                <label htmlFor="confPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className={
                    "form-control " +
                    (!confPassword ? "" : passMatch ? "is-valid" : "is-invalid")
                  }
                  id="confPassword"
                  placeholder="********"
                  minLength={8}
                  value={confPassword}
                  onChange={(e) => setConfPassword(e.target.value)}
                  required
                />
                <div className="invalid-feedback">Must match the password.</div>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={loading}
            >
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={
                loading ||
                exist ||
                (password && !checkPassword) ||
                (password && !passMatch)
              }
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default SettingsModal;
