import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function EditUserModal({ user, msg }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [premium, setPremium] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === "0") {
      setPremium("");
    }
  }, [type]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setType(user.type.toString());
    setPremium(user.premium);
    setLoading(false);
    setShow(true);
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const updateUser = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await axiosJWT.patch(`${apiUrl}/user/${user._id}`, {
        type,
        premium,
      });
      handleClose();
      msg(response.data.msg);
    } catch (error) {
      setLoading(false);
      navigate("/dashboard");
    }
  };

  return (
    <>
      <Button
        className="btn btn-sm btn-primary"
        onClick={handleShow}
        title="Edit"
      >
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <form onSubmit={updateUser}>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-6">
                <div className="mb-3 mb-sm-0">
                  <label htmlFor="type" className="form-label">
                    Type
                  </label>
                  <select
                    className="form-select"
                    id="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value={0}>Free</option>
                    <option value={1}>Premium</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div>
                  <label htmlFor="premium" className="form-label">
                    Premium Until
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="premium"
                    value={premium}
                    onChange={(e) => setPremium(e.target.value)}
                    required={type === "1"}
                    disabled={type === "0"}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={loading}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Update"
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default EditUserModal;
