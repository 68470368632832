import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import QRModal from "../components/QRModal";
import AddModal from "../components/AddModal";
import ClearModal from "../components/ClearModal";
import DeleteModal from "../components/DeleteModal";
import EditModal from "../components/EditModal";

const Dashboard = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = window.location.href.split("/dashboard").join("");
  const navigate = useNavigate();

  const [user, setUser] = useState([]);
  const [links, setLinks] = useState([]);
  const [showRow, setShowRow] = useState([false, ""]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getLinks();
    // eslint-disable-next-line
  }, []);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        const decoded = jwtDecode(response.data.accessToken);
        setUser(decoded.user);
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getLinks = async () => {
    try {
      const response = await axiosJWT.get(`${apiUrl}/links`);
      setLinks(response.data.links);
      setLoading(false);
    } catch (error) {}
  };

  const resend = async () => {
    try {
      const response = await axiosJWT.post(`${apiUrl}/email`);
      toastify(response.data.msg);
    } catch (error) {}
  };

  const refresh = async () => {
    setLoading(true);
    await getLinks();
    setLoading(false);
  };

  const handlerMsg = (msg) => {
    toastify(msg);
    getLinks();
  };

  const toastify = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const trim = (link) => {
    if (link.length > 27) {
      link = link.substring(0, 27) + "...";
    }
    return link;
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar loading={loading} user={user} msg={handlerMsg} />
      <div className="container mt-5 py-4 py-md-5">
        {user.name && !user.verify ? (
          <div className="alert alert-warning mb-3" role="alert">
            Your email hasn't been verified, please check your email (
            {user.email}
            ). <Link onClick={resend}>Resend Email</Link>
          </div>
        ) : (
          ""
        )}
        {!user.type && user.limit === 0 ? (
          <div className="alert alert-warning mb-3" role="alert">
            Free Access limit reached! You can't shorten links any more.{" "}
            <Link
              to={
                "https://wa.me/6283120565298?text=Hello%2C%20I%20want%20to%20upgrade%20my%20LinkIn%20account"
              }
              target="_blank"
            >
              Upgrade to get Premium Access
            </Link>
          </div>
        ) : user.premium === new Date().toLocaleDateString("en-CA") ? (
          <div className="alert alert-warning mb-3" role="alert">
            Your Premium Access will expire tomorrow.{" "}
            <Link
              to={
                "https://wa.me/6283120565298?text=Hello%2C%20I%20want%20to%20renew%20my%20LinkIn%20account"
              }
              target="_blank"
            >
              Renew Premium Access now
            </Link>
          </div>
        ) : (
          ""
        )}

        <h3>Dashboard</h3>
        <div className="table-responsive">
          <table className="table caption-top align-middle table-hover">
            <caption>
              Your Links{" "}
              <div className="float-end">
                <button
                  className="btn btn-secondary btn-sm me-1"
                  title="Refresh"
                  onClick={() => refresh()}
                  disabled={loading}
                >
                  <i className="bi bi-arrow-clockwise"></i>
                </button>
                <AddModal user={user} msg={handlerMsg} />
                <ClearModal length={links.length} msg={handlerMsg} />
              </div>
            </caption>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Short Link</th>
                <th scope="col" className="d-none d-md-table-cell">
                  Full Link
                </th>
                <th scope="col" className="d-none d-md-table-cell">
                  Visitors
                </th>
                <th scope="col" colSpan={3}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                links.length !== 0 ? (
                  links.map((link, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <th
                          scope="row"
                          className="text-nowrap"
                          onClick={() =>
                            showRow[0] && showRow[1] === link._id
                              ? setShowRow([false, link._id])
                              : setShowRow([true, link._id])
                          }
                        >
                          {showRow[0] && showRow[1] === link._id ? (
                            <i className="bi bi-caret-down-fill me-1 d-inline d-md-none"></i>
                          ) : (
                            <i className="bi bi-caret-right-fill me-1 d-inline d-md-none"></i>
                          )}
                          {index + 1}
                        </th>
                        <td>
                          <Link
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${url}/${link.short}`
                              );
                              toastify("Copied!");
                            }}
                            title="Copy to Clipboard"
                          >
                            {link.short}
                          </Link>
                        </td>
                        <td className="d-none d-md-table-cell">
                          <Link
                            to={link.full}
                            target="_blank"
                            title="Open Link"
                          >
                            {trim(link.full)}
                          </Link>
                        </td>
                        <td className="d-none d-md-table-cell">
                          {link.visitor}
                        </td>
                        <td width={10} className="text-nowrap">
                          <QRModal short={link.short} msg={toastify} />
                          <EditModal
                            userType={user.type}
                            link={link}
                            msg={handlerMsg}
                          />
                          <DeleteModal id={link._id} msg={handlerMsg} />
                        </td>
                      </tr>
                      {showRow[0] && showRow[1] === link._id ? (
                        <tr className="d-table-row d-md-none">
                          <td colSpan={5}>
                            <ul className="m-0">
                              <li>
                                <b>Full Link</b>:{" "}
                                <Link to={link.full} target="_blank">
                                  {trim(link.full)}
                                </Link>
                              </li>
                              <li>
                                <b>Visitors</b>: {link.visitor}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      You don't have a short link yet
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Dashboard;
