import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import EditUserModal from "../components/EditUserModal";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../components/Footer";

const Users = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [user, setUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [showRow, setShowRow] = useState([false, ""]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        const decoded = jwtDecode(response.data.accessToken);
        setUser(decoded.user);
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getUsers = async () => {
    try {
      const response = await axiosJWT.get(`${apiUrl}/users`);
      setUsers(response.data.users);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        navigate("/dashboard");
      }
    }
  };

  const handlerMsg = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
    getUsers();
  };

  const dateToString = (date) => {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth();
    const year = new Date(date).getFullYear();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return day + " " + months[month] + " " + year;
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar user={user} />
      {!loading ? (
        <div className="container mt-5 py-4 py-md-5">
          <h3>Users</h3>
          <div className="table-responsive">
            <table className="table caption-top align-middle table-hover">
              <caption>LinkIn users</caption>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Email</th>
                  <th scope="col" className="d-none d-md-table-cell">
                    Premium
                  </th>
                  <th scope="col" className="d-none d-md-table-cell">
                    Limit
                  </th>
                  <th scope="col" colSpan={3}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.length !== 0 ? (
                  users.map((user, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <th
                          scope="row"
                          className="text-nowrap"
                          onClick={() =>
                            showRow[0] && showRow[1] === user._id
                              ? setShowRow([false, user._id])
                              : setShowRow([true, user._id])
                          }
                        >
                          {showRow[0] && showRow[1] === user._id ? (
                            <i className="bi bi-caret-down-fill me-1 d-inline d-md-none"></i>
                          ) : (
                            <i className="bi bi-caret-right-fill me-1 d-inline d-md-none"></i>
                          )}
                          {index + 1}
                        </th>
                        <td>{user.email}</td>
                        <td className="d-none d-md-table-cell">
                          {user.premium ? dateToString(user.premium) : "-"}
                        </td>
                        <td className="d-none d-md-table-cell">{user.limit}</td>
                        <td width={10} className="text-nowrap">
                          <EditUserModal user={user} msg={handlerMsg} />
                        </td>
                      </tr>
                      {showRow[0] && showRow[1] === user._id ? (
                        <tr className="d-table-row d-md-none">
                          <td colSpan={5}>
                            <ul className="m-0">
                              <li>
                                <b>Premium</b>:{" "}
                                {user.premium
                                  ? dateToString(user.premium)
                                  : "-"}
                              </li>
                              <li>
                                <b>Limit</b>: {user.limit}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      You don't have a user yet
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default Users;
