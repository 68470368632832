import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Verify = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line
  });

  const verifyEmail = async () => {
    try {
      const response = await axios.get(`${apiUrl}/email`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(response.data.msg, {
        position: "bottom-right",
      });
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      if (error.response) {
        toast.error("Invalid or expired link.", {
          position: "bottom-right",
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      }
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100">
      <div className="d-block text-center">
        <h3>
          Link<span className="text-danger">In</span>
        </h3>
        <div
          className="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Verify;
