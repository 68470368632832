import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-body-tertiary text-center text-lg-start mt-auto">
      <div className="text-center p-2">
        <small>
          &copy; 2024 <Link to="/">LinkIn</Link>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
